import React, { useState } from "react";
import { routes } from "../../helpers/constants";
import { useLocation, useNavigate } from "react-router-dom";
import MenuButton from "../Common/MenuButton";
import CardAssign from "../Common/CardAssign";
import { useDispatch } from "react-redux";
import { reqToGetTerritoryMDList } from "../../store/slice/cardAssignSlice";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const userReducer =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  const [showCardAssign, setShowCardAssign] = useState(false);
  const handleCardAssignShow = () => {
    if (showCardAssign === true) {
      setShowCardAssign(!showCardAssign);
    } else {
      dispatch(
        reqToGetTerritoryMDList({
          onSuccess: () => {
            setShowCardAssign(!showCardAssign);
          },
        })
      );
    }
  };

  return (
    <>
      {location.pathname !== "/product" && (
        <div className="tc-navbar-section">
          <div className="tc-navbar-content">
            <div className="tc-navbar-menu" id="menu-btn">
              <MenuButton handleCardAssignShow={handleCardAssignShow} />
            </div>
            <div className="tc-navbar-text">
              <h2>
                {userReducer &&
                  userReducer.firstname + " " + userReducer.lastname}
              </h2>
            </div>
            <div
              className="tc-navbar-icon"
              onClick={() => {
                navigate(routes.product);
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5.99999 2.82349C5.41521 2.82349 4.94116 3.29754 4.94116 3.88231V5.82349C4.94116 6.40826 5.41521 6.88231 5.99999 6.88231H18C18.5848 6.88231 19.0588 6.40826 19.0588 5.82349V3.88231C19.0588 3.29754 18.5848 2.82349 18 2.82349H5.99999Z" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.35293 7.41172H17.8235V18.3529C17.8235 19.9123 16.5594 21.1764 15 21.1764H9.17646C7.61707 21.1764 6.35293 19.9123 6.35293 18.3529V7.41172ZM13.2353 10.4117H10.7647V12.5294H8.64704V15H10.7647V17.1176H13.2353V15H15.3529V12.5294H13.2353V10.4117Z"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
      {/* Assigment modal */}
      <CardAssign
        userReducer={userReducer}
        showCardAssign={showCardAssign}
        handleCardAssignShow={handleCardAssignShow}
      />
    </>
  );
};

export default Navbar;
