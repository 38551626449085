import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { reqToSendOTP } from "../../store/slice/userSlice";
import { useNavigate } from "react-router-dom";
import { routes } from "../../helpers/constants";
import { notify } from "../../helpers/commonFunction";
import { messages } from "../../helpers/commonMessages";
import Loader from "../../component/Common/Loader";

const SendOtp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userReducer = useSelector((state) => state.userReducer);

  const [mobileNumber, setMobileNumber] = useState();

  // Handle valid moblie number and Send OTP
  const handleValidUserMNumber = () => {
    if (mobileNumber === undefined) {
      notify(messages.mobile_req, "error");
    } else {
      dispatch(
        reqToSendOTP({
          data: { mobile_no: mobileNumber },
          onSuccess: (response) => {
            if (response) {
              navigate(routes.verifyotp, {
                state: {
                  mobileNumber: mobileNumber,
                },
              });
            }
          },
        })
      );
    }
  };

  return (
    <>
      {userReducer.loader && <Loader />}
      <div className="tc-login-section">
        <div className="tc-login-content">
          <a href="/" className="tc-logo">
            T-Card
          </a>
          <PhoneInput
            className="tc-number-input"
            international
            defaultCountry="IN"
            value={mobileNumber}
            onChange={setMobileNumber}
          />
          <button className="tc-save" onClick={() => handleValidUserMNumber()}>
            Log in
          </button>
        </div>
      </div>
    </>
  );
};

export default SendOtp;
