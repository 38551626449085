import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";

import Loader from "../../../component/Common/Loader";
import dataNotFound from "../../../images/DataNotFound.svg";
import { numberFormating } from "../../../helpers/commonFunction";
import moment from "moment";

const Extrapolation = (props) => {
  const {
    showExtrapolation,
    handleExtrapolationModal,
    getExtrapolationProductList,
  } = props;

  // Get extrapolation reducer data
  const extrapolationReducer = useSelector(
    (state) => state.extrapolationReducer
  );
  const extrapolationMDList = extrapolationReducer.extrapolationMDList;

  const [loader, setLoader] = useState(false);
  const [filterMDList, setFilterMDList] = useState("roi");

  // Handle onchange filter MD list
  const onChangeMDList = (e) => {
    setLoader(true);
    setTimeout(() => {
      setFilterMDList(e.target.value);
      setLoader(false);
    }, 1000);
  };

  return (
    <>
      {loader && <Loader />}
      <div
        className={`tc-extrapolation-section tc-sidebar-section ${
          showExtrapolation && "tc-extrapolation-sidebar"
        }`}
      >
        <div className="tc-product-navbar">
          <button
            type="button"
            className="tc-product-menu"
            onClick={() => {
              handleExtrapolationModal("md");
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" />
            </svg>
          </button>
          <div className="tc-product-text">
            <h2>Extrapolation</h2>
          </div>
        </div>
        <div className="tc-product-body">
          <div className="tc-body-content">
            <h1 className="tc-main-title">Top MDs</h1>
            <div className="tc-daily-number">
              <p className="tc-number-text">
                <span>as of {moment().format("MMM YYYY")}</span>
              </p>
            </div>
            <Form.Select
              className="tc-select-box"
              onChange={(e) => onChangeMDList(e)}
            >
              <option value="roi">ROI Report</option>
              <option value="budget">Budget</option>
              <option value="ext">Ext-Amount</option>
            </Form.Select>
            <Table className="tc-table tc-daily-table tc-weekly-table">
              <thead>
                <tr>
                  <th>md</th>
                  <th>
                    {filterMDList === "roi"
                      ? "roi"
                      : filterMDList === "budget"
                      ? "budget"
                      : "Ext"}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {extrapolationMDList && extrapolationMDList.length === 0 ? (
                  <tr>
                    <td colSpan="3">
                      <div className="tc-datanot-found">
                        <img src={dataNotFound} alt="Data Not Found" />
                        <p>Data not found</p>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {extrapolationMDList &&
                      extrapolationMDList.map((mdData, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {mdData?.Doctor?.firstname.concat(
                                " " + mdData?.Doctor?.lastname
                              )}
                            </td>
                            <td>
                              {filterMDList === "roi"
                                ? mdData?.roi
                                : filterMDList === "budget"
                                ? numberFormating(mdData?.budget)
                                : numberFormating(mdData?.total_ea)}
                            </td>
                            <td>
                              <div
                                className="tc-sidebar-btn"
                                onClick={() =>
                                  getExtrapolationProductList(mdData)
                                }
                              >
                                <FontAwesomeIcon icon={faCircleArrowRight} />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Extrapolation;
