export const routes = {
  all: "*",
  homepage: "/",
  home: "/home",
  login: "/login",
  product: "/product",
  verifyotp: "/verifyotp",
  dailyreport: "/dailyReport",
  weeklyreport: "/weeklyReport",
  monthlyreport: "/monthlyReport",
};
