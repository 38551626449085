import React, { memo } from "react";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import TCard from "../TCard";
import moment from "moment";

const CardModal = (props) => {
  const { carddata, patientdata } = props;
  return (
    <Modal
      {...props}
      size="false"
      className="tc-model-dialog tc-card-model"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="tc-model-header" closeButton>
        <Modal.Title
          className="tc-model-header-title"
          id="contained-modal-title-vcenter"
        >
          Patient Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="tc-model-body">
        <h2 className="tc-model-title">{carddata?.serial_no}</h2>
        <TCard card={carddata} />
        <div className="tc-place-section">
          <div className="tc-place-section-box-row">
            <h2 className="tc-place-title">Age:</h2>
            <p className="tc-place-name">{patientdata?.patient_details?.age}</p>
          </div>
          <div className="tc-place-section-box-row">
            <h2 className="tc-place-title">Sex:</h2>
            <p className="tc-place-name">{patientdata?.patient_details?.sex}</p>
          </div>
          <div className="tc-place-section-box-row">
            <h2 className="tc-place-title">Location:</h2>
            <p className="tc-place-name">
              {patientdata?.patient_details?.Location?.name}
            </p>
          </div>
          <div className="tc-place-section-box-row">
            <h2 className="tc-place-title">Birthdate:</h2>
            <p className="tc-place-name">
              {moment(patientdata?.patient_details?.birthdate).format("LL")}
            </p>
          </div>
        </div>
        <Table className="tc-table">
          <thead>
            <tr>
              <th>PRODUCT</th>
              <th>QTY</th>
              <th>AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            {patientdata &&
              patientdata?.product_list?.map((product, index) => {
                return (
                  <tr key={index}>
                    <td>{product.name}</td>
                    <td>{product.product_qty}</td>
                    <td>{product.product_total}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default memo(CardModal);
