import React from "react";
import Qrcode from "../../images/Qr-code.png";

const TCard = (props) => {
  return (
    <div className="tc-card-section">
      <div className="tc-card-content">
        <h2 className="tc-card-text mt-0">Save on your medicines</h2>
        <div className="tc-card-qr">
          <h2 className="tc-card-qr-text">t card</h2>
          <div className="tc-card-qr-inner">
            <img src={Qrcode} className="tc-card-qr-img" alt="QR code images" />
            <p className="tc-card-qr-code">{props?.card?.serial_no}</p>
          </div>
        </div>
        <h2 className="tc-card-text mb-0">go to tipidcard.com </h2>
      </div>
    </div>
  );
};

export default TCard;
