import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { reqToAssignCard } from "../../store/slice/cardAssignSlice";
import { messages } from "../../helpers/commonMessages";

const CardAssign = (props) => {
  const { userReducer, showCardAssign, handleCardAssignShow } = props;
  const dispatch = useDispatch();

  // Get card assign reducer data
  const cardAssignReducer = useSelector((state) => state.cardAssignReducer);
  const territoryMDList = cardAssignReducer?.territoryMDList;

  const [errors, setErrors] = useState({});
  const [assignValues, setAssignValues] = useState({
    md: "",
    serialno: "",
    qty: "",
  });

  // Handle validation
  const validation = () => {
    const validObj = {};
    if (assignValues.md === "") {
      validObj.md = messages.md_req;
    }
    if (assignValues.serialno === "") {
      validObj.serialno = messages.serialno_req;
    }
    if (assignValues.qty === "") {
      validObj.qty = messages.qty_req;
    }
    setErrors(validObj);
    return Object.values(validObj).every((x) => x === "");
  };

  // Handle onChange value
  const onChangeValues = (e) => {
    const { name, value } = e.target;
    setAssignValues({ ...assignValues, [name]: value });
    setErrors({});
  };

  // Assign card to MD
  const handleAssignCardToMD = (e) => {
    e.preventDefault();
    const tempObj = {
      doctor: Number(assignValues?.md),
      starting_serial_no: assignValues?.serialno,
      qty: assignValues.qty,
    };
    if (validation()) {
      dispatch(
        reqToAssignCard({
          data: tempObj,
          onSuccess: () => {
            handleCardAssignShow();
            setAssignValues({ ...assignValues, md: "", serialno: "", qty: "" });
          },
        })
      );
    }
  };

  return (
    <div
      className={`tc-assigment-section tc-sidebar-section ${
        showCardAssign && "tc-assigment-sidebar"
      }`}
    >
      <div className="tc-product-navbar">
        <button
          className="tc-product-menu"
          onClick={() => {
            setErrors("");
            setAssignValues({ ...assignValues, md: "", serialno: "", qty: "" });
            handleCardAssignShow();
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" />
          </svg>
        </button>
        <div className="tc-product-text">
          <h2>Assigment</h2>
        </div>
      </div>
      <div className="tc-product-body">
        <Form className="tc-assigment-form">
          <div className="tc-assigment-form-item">
            <Form.Group className="tc-assigment-form-list">
              <p className="tc-assigment-user-name">
                MR:{" "}
                {userReducer &&
                  userReducer?.firstname + " " + userReducer?.lastname}
              </p>
            </Form.Group>
            <Form.Group className="tc-assigment-form-list">
              <div className="tc-assigment-form-list-input-row">
                <Form.Label>MD: </Form.Label>
                <Form.Select
                  className="tc-assigment-option"
                  aria-label="Default select example"
                  name="md"
                  value={assignValues.md}
                  onChange={(e) => onChangeValues(e)}
                >
                  <option value="">Select MD</option>
                  {territoryMDList &&
                    territoryMDList.map((mdData, index) => {
                      return (
                        <option key={index} value={mdData.doctor_id}>
                          {mdData?.firstname.concat(" " + mdData?.lastname)}
                        </option>
                      );
                    })}
                </Form.Select>
              </div>
              <span className="tc-error-text">{errors && errors.md}</span>
            </Form.Group>
            <Form.Group className="tc-assigment-form-list">
              <div className="tc-assigment-form-list-input-row">
                <Form.Label>S.N:</Form.Label>
                <Form.Control
                  className="tc-assigment-option"
                  type="text"
                  name="serialno"
                  value={assignValues.serialno}
                  onChange={(e) => onChangeValues(e)}
                  placeholder="Enter serial number"
                />
              </div>
              <span className="tc-error-text">{errors && errors.serialno}</span>
            </Form.Group>
            <Form.Group className="tc-assigment-form-list">
              <div className="tc-assigment-form-list-input-row">
                <Form.Label>Quantity</Form.Label>
                <Form.Control
                  className="tc-assigment-option"
                  type="text"
                  name="qty"
                  value={assignValues.qty}
                  onChange={(e) => onChangeValues(e)}
                  placeholder="Enter quantity"
                />
              </div>
              <span className="tc-error-text">{errors && errors.qty}</span>
            </Form.Group>
          </div>
          <button
            className="tc-save"
            type="submit"
            onClick={(e) => handleAssignCardToMD(e)}
          >
            save
          </button>
        </Form>
      </div>
    </div>
  );
};

export default CardAssign;
