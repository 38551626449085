import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";

// Get extrapolation MD List
export const reqToGetExtrapolationMDList = createAsyncThunk(
  "reqToGetExtrapolationMDList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/extrapolation-reports/md-list",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get extrapolation product List
export const reqToGetExtrapolationProdList = createAsyncThunk(
  "reqToGetExtrapolationProdList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/extrapolation-reports/product-list-by-md",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Create initial state
const initialState = {
  loader: false,
  error: "",
  extrapolationMDList: [],
  extrapolationProductList: [],
};

// Create extrapolation slice
const extrapolationSlice = createSlice({
  name: "extrapolationReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get MD list
    builder.addCase(reqToGetExtrapolationMDList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetExtrapolationMDList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.extrapolationMDList = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetExtrapolationMDList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get product list
    builder.addCase(reqToGetExtrapolationProdList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(
      reqToGetExtrapolationProdList.fulfilled,
      (state, action) => {
        state.loader = false;
        if (action?.payload?.status === 200) {
          state.extrapolationProductList = action.payload.data;
        } else if (
          action.payload.response &&
          action.payload.response.status !== 200
        ) {
          notify(action.payload.response.data, "error");
        } else {
          notify(action.payload.message, "error");
        }
      }
    );
    builder.addCase(reqToGetExtrapolationProdList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default extrapolationSlice.reducer;
