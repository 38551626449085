import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";

// Get monthly report data
export const reqToGetMonthlyReportData = createAsyncThunk(
  "reqToGetMonthlyReportData",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/card-reports/monthly",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get month wise MD list
export const reqToGetMonthWiseMDList = createAsyncThunk(
  "reqToGetMonthWiseMDList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/card-reports/monthly/md-list",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get month wise card list
export const reqToGetMonthWiseCardList = createAsyncThunk(
  "reqToGetMonthWiseCardList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/card-reports/monthly/card-list",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get month wise product list
export const reqToGetMonthWiseProductList = createAsyncThunk(
  "reqToGetMonthWiseProductList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/card-reports/monthly/product-list",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Create initial state
const initialState = {
  loader: false,
  error: "",
  monthlyReportData: [],
  monthlyReportMDList: [],
  monthlyReportCardList: [],
  monthlyReportProductList: [],
};

// Create monthly report slice
const monthlyReportSlice = createSlice({
  name: "monthlyReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Monthly report data
    builder.addCase(reqToGetMonthlyReportData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetMonthlyReportData.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.monthlyReportData = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetMonthlyReportData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Monthly report MD list
    builder.addCase(reqToGetMonthWiseMDList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetMonthWiseMDList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.monthlyReportMDList = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetMonthWiseMDList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Monthly report card list
    builder.addCase(reqToGetMonthWiseCardList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetMonthWiseCardList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.monthlyReportCardList = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetMonthWiseCardList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Monthly report product list
    builder.addCase(reqToGetMonthWiseProductList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetMonthWiseProductList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.monthlyReportProductList = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetMonthWiseProductList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default monthlyReportSlice.reducer;
