import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { Form, Table } from "react-bootstrap";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Loader from "../../component/Common/Loader";
import DailyMdDetails from "./DailyMdDetails";
import DailyCardDetails from "./DailyCardDetails";
import dataNotFound from "../../images/DataNotFound.svg";
import { getStartDateAndEndDate } from "../../helpers/commonFunction";

// API
import {
  reqToGetDailyReportData,
  reqToGetDayWiseCardList,
  reqToGetDayWiseMDList,
  reqToGetDayWiseProductList,
} from "../../store/slice/dailyReportSlice";

const DailyReport = () => {
  const dispatch = useDispatch();
  const date = new Date();
  const userReducer =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  // Get daily reducer data
  const dailyReportReducer = useSelector((state) => state.dailyReportReducer);
  const dailyReportData =
    dailyReportReducer && dailyReportReducer?.dailyReportData;

  // Default days data of week
  const initialState = [
    {
      qty: 0,
      weekday: 0,
      card_total: 0,
      p_date: "",
      isVisible: false,
    },
    {
      qty: 0,
      weekday: 1,
      card_total: 0,
      p_date: "",
      isVisible: false,
    },
    {
      qty: 0,
      weekday: 2,
      card_total: 0,
      p_date: "",
      isVisible: false,
    },
    {
      qty: 0,
      weekday: 3,
      card_total: 0,
      p_date: "",
      isVisible: false,
    },
    {
      qty: 0,
      weekday: 4,
      card_total: 0,
      p_date: "",
      isVisible: false,
    },
    {
      qty: 0,
      weekday: 5,
      card_total: 0,
      p_date: "",
      isVisible: false,
    },
    {
      qty: 0,
      weekday: 6,
      card_total: 0,
      p_date: "",
      isVisible: false,
    },
  ];

  const [weeksList, setWeeksList] = useState([]);
  const [totalCardAmount, setTotalCardAmount] = useState(0);
  const [reportDataArray, setReportDataArray] = useState(initialState);

  // Sidebar states
  const [showMDList, setShowMDList] = useState(false);
  const [showCardList, setShowCardList] = useState(false);

  // Selected data states
  const [selectWeek, setSelectWeek] = useState(1);
  const [selectedDayData, setSelectedDayData] = useState();
  const [selectedMDData, setSelectedMDData] = useState();
  const [selectedProductData, setSelectedProductData] = useState();
  const [dailyChartData, setDailyChartData] = useState({
    options: {
      chart: {
        type: "bar",
        height: 250,
        toolbar: {
          show: false,
        },
      },
      colors: ["#96da7a", "#fef99b"],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["#000"],
      },
      xaxis: {
        categories: ["Mon", "Tue", "Wed", "Thus", "Fri", "Sat"],
      },
      grid: {
        show: false,
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: false,
      },
      series: [
        {
          name: "Series 1",
          data: [0, 0, 0, 0, 0, 0, 0],
        },
      ],
      noData: {
        text: "Data not found",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -30,
        style: {
          color: "#000",
          fontSize: "16px",
          fontFamily: "Cabin, sans-serif",
        },
      },
    },
  });

  // Handle MD list sidebar
  const handleShowMDList = () => {
    setShowMDList(!showMDList);
  };

  // Handle card list sidebar
  const handleShowCardList = () => {
    setShowCardList(!showCardList);
  };

  // Get week list and Weeks by dates
  useEffect(() => {
    const getWeeksList = getStartDateAndEndDate("week", "", 4);
    if (getWeeksList) setWeeksList(getWeeksList);
    let getDates = getStartDateAndEndDate("day", selectWeek, 4);
    dispatch(
      reqToGetDailyReportData({
        data: {
          start_date: getDates.startDate,
          end_date: getDates.endDate,
        },
        onSuccess: (response) => {
          if (response && response.length > 0) {
            updateDailyReportData(response);
          }
        },
      })
    );
  }, []);

  // Update daily report chart data
  useEffect(() => {
    let data = reportDataArray.map((item) => item.qty);
    let checkData = reportDataArray.every((item) => item.qty === 0);
    setDailyChartData({
      ...dailyChartData,
      options: {
        ...dailyChartData.options,
        series: [
          {
            name: "Card",
            data: checkData === false ? data : [],
          },
        ],
      },
    });
  }, [reportDataArray]);

  // Get daily report by selected weeks
  const getDailyReportData = (event) => {
    setSelectWeek(event.target.value);
    let getDates = getStartDateAndEndDate("day", event.target.value, 4);
    dispatch(
      reqToGetDailyReportData({
        data: {
          start_date: getDates.startDate,
          end_date: getDates.endDate,
        },
        onSuccess: (response) => {
          if (response && response.length > 0) {
            updateDailyReportData(response);
          } else {
            setReportDataArray(initialState);
          }
        },
      })
    );
  };

  // Update reportDataArray data and Count total card amount
  const updateDailyReportData = (data) => {
    let count = 0;
    for (let i = 0; i < data.length; i++) {
      setReportDataArray((prevState) => {
        const newState = prevState.map((prev) => {
          if (prev.weekday === data[i].weekday) {
            return { ...data[i], isVisible: true };
          }
          return prev;
        });
        return newState;
      });

      count = count + data[i]?.card_total;
      setTotalCardAmount(count);
    }
  };

  // Get MD data by selected date
  const getDailyReportMDList = (item) => {
    setSelectedDayData(item);
    dispatch(
      reqToGetDayWiseMDList({
        data: {
          report_date: item?.p_date,
        },
        onSuccess: () => {
          handleShowMDList();
        },
      })
    );
  };

  // Get card details by selected MD
  const getDailyReportCardList = (item) => {
    setSelectedMDData(item);
    dispatch(
      reqToGetDayWiseCardList({
        data: {
          report_date: selectedDayData?.p_date,
          doctor: item?.doctor_id,
        },
        onSuccess: () => {
          handleShowCardList();
          setSelectedProductData("");
        },
      })
    );
  };

  // Get product details by selected card
  const getDailyReportProductList = (item) => {
    if (selectedProductData?.card_id !== item?.card_id) {
      dispatch(
        reqToGetDayWiseProductList({
          data: {
            report_date: selectedDayData?.p_date,
            card: item.card_id,
          },
          onSuccess: () => {
            setSelectedProductData(item);
          },
        })
      );
    } else {
      setSelectedProductData("");
    }
  };

  return (
    <>
      {dailyReportReducer && dailyReportReducer?.loader && <Loader />}
      <div className="tc-body-section">
        <div className="tc-body-content">
          <h1 className="tc-main-title">TCard per Day</h1>
          <div className="tc-daily-number">
            <div className="tc-select-box">
              <Form.Select onChange={(e) => getDailyReportData(e)}>
                {weeksList &&
                  weeksList?.weekData &&
                  weeksList?.weekData?.length > 0 &&
                  weeksList?.weekData.map((week, index) => {
                    return (
                      <option value={week?.weekDetails?.value} key={index}>
                        {week?.weekDetails?.label}
                      </option>
                    );
                  })}
              </Form.Select>
              of {weeksList?.month} {weeksList?.year}
            </div>
            <p className="tc-number-text">
              Total:{" "}
              <span className="tc-text-success">
                {" "}
                ₽ {totalCardAmount && totalCardAmount}
              </span>
            </p>
          </div>
          <div className="tc-daily-chart">
            <ReactApexChart
              options={dailyChartData?.options}
              series={dailyChartData?.options?.series}
              type="bar"
              height={250}
            />
          </div>
          <Table className="tc-table tc-daily-table">
            <thead>
              <tr>
                <th>day</th>
                <th>qty</th>
                <th>amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {dailyReportData && dailyReportData.length === 0 ? (
                <tr>
                  <td colSpan="4">
                    <div className="tc-datanot-found">
                      <img src={dataNotFound} alt="Data Not Found" />
                      <p>Data not found</p>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {reportDataArray &&
                    reportDataArray.map((dayReport, index) => {
                      return (
                        <tr
                          key={index}
                          className={`${
                            dayReport?.isVisible === false &&
                            "tc-table-row-disabled"
                          }`}
                        >
                          <td>{dayReport?.weekday + 1}</td>
                          <td>{dayReport?.qty}</td>
                          <td>{dayReport?.card_total}</td>
                          <td>
                            <div
                              className="tc-sidebar-btn"
                              onClick={() => getDailyReportMDList(dayReport)}
                            >
                              <FontAwesomeIcon icon={faCircleArrowRight} />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </Table>

          {/* Daily report MD list sidebar*/}
          <DailyMdDetails
            showMDList={showMDList}
            userReducer={userReducer}
            selectedDayData={selectedDayData}
            handleShowMDList={handleShowMDList}
            getDailyReportCardList={getDailyReportCardList}
          />

          {/* Daily report card list sidebar*/}
          <DailyCardDetails
            showCardList={showCardList}
            userReducer={userReducer}
            selectedMDData={selectedMDData}
            selectedDayData={selectedDayData}
            handleShowCardList={handleShowCardList}
            selectedProductData={selectedProductData}
            getDailyReportProductList={getDailyReportProductList}
          />
        </div>
      </div>
    </>
  );
};

export default DailyReport;
