import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import CardModal from "../../component/Common/Modals/CardModal";
import TCard from "../../component/Common/TCard";
import MainModal from "../../component/Common/Modals/MainModal";
import { useDispatch, useSelector } from "react-redux";
import {
  reqToGetCardList,
  reqToGetCardUsagesByMD,
} from "../../store/slice/cardSlice";
import moment from "moment/moment";
import { reqToGetPatientList } from "../../store/slice/patientSlice";
import Loader from "../../component/Common/Loader";
import dataNotFound from "../../images/DataNotFound.svg";

const Home = () => {
  const dispatch = useDispatch();
  const userReducer =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  // Get card data and Card usages by md list
  const cardReducer = useSelector((state) => state.cardReducer);
  const cardsDetails = cardReducer?.cardsDetails;
  const cardUsagesByMDList = cardReducer?.cardUsagesByMDList;

  // Get patient reducer
  const patientReducer = useSelector((state) => state.patientReducer);

  // Get card assign reducer
  const cardAssignReducer = useSelector((state) => state.cardAssignReducer);

  const [selectedCard, setSelectedCard] = useState();

  // Modal states
  const [showQtyModal, setShowQtyModal] = useState(false);
  const [showPatientModal, setShowPatientModal] = useState(false);
  const date = "2023-05-29";
  const handlePatientModal = () => {
    setShowPatientModal(false);
    dispatch(reqToGetCardList({ today_date: date }));
    dispatch(reqToGetCardUsagesByMD({ today_date: date }));
  };

  // Get card data and card use by md data API calling
  useEffect(() => {
    dispatch(reqToGetCardList({ today_date: date }));
    dispatch(reqToGetCardUsagesByMD({ today_date: date }));
  }, [dispatch]);

  // Get selected card data and Show patient modal
  const handleShowPatientDetails = (cardData) => {
    const tempObj = {
      pid: cardData?.patient_id,
      cid: cardData?.card_id,
    };
    setSelectedCard(cardData);
    dispatch(
      reqToGetPatientList({
        data: tempObj,
        onSuccess: () => {
          setShowPatientModal(true);
        },
      })
    );
  };

  return (
    <>
      {patientReducer && patientReducer?.loader ? (
        <Loader />
      ) : cardReducer && cardReducer?.loader ? (
        <Loader />
      ) : (
        cardAssignReducer && cardAssignReducer?.loader && <Loader />
      )}
      <div className="tc-body-section">
        <div
          className={`tc-body-content ${
            cardsDetails && cardsDetails.length === 0 && "tc-home-body-content"
          }`}
        >
          <h1 className="tc-main-title">T-Card Live</h1>
          <div className="tc-number">
            <p
              className={`tc-number-text tc-cursor-pointer ${
                cardUsagesByMDList?.cards_qty === 0 ||
                (cardUsagesByMDList?.cards_qty === undefined &&
                  "tc-table-row-disabled")
              }`}
              onClick={() => setShowQtyModal(true)}
            >
              Today:{" "}
              <span>
                {cardUsagesByMDList && cardUsagesByMDList?.cards_qty > 0
                  ? cardUsagesByMDList?.cards_qty
                  : 0}
              </span>
            </p>
            <p className="tc-number-text">
              Total:{" "}
              <span>
                ₽
                {cardUsagesByMDList && cardUsagesByMDList?.grand_total > 0
                  ? cardUsagesByMDList?.grand_total
                  : 0}
              </span>
            </p>
          </div>
          {cardsDetails && cardsDetails.length === 0 ? (
            <div className="tc-datanot-found">
              <img src={dataNotFound} alt="Data Not Found" />
              <p>Data not found</p>
            </div>
          ) : (
            <>
              {cardsDetails &&
                cardsDetails?.map((card, index) => {
                  return (
                    <div className="tc-card-item" key={index}>
                      <h2 className="tc-serial-number">{card?.serial_no}</h2>
                      <p className="tc-date-text">
                        {moment(card?.purchase_date).format("L") +
                          " - " +
                          moment(card?.purchase_date).format("LT")}
                      </p>
                      <div
                        variant="transprent"
                        onClick={() => handleShowPatientDetails(card)}
                      >
                        <TCard card={card} />
                      </div>

                      <div className="tc-place-section">
                        <div className="tc-place-section-box-row">
                          <h2 className="tc-place-title">MD:</h2>
                          <p className="tc-place-name">{card?.md_firstname}</p>
                        </div>
                        <div className="tc-place-section-box-row">
                          <h2 className="tc-place-title">MR:</h2>
                          <p className="tc-place-name">
                            {userReducer &&
                              userReducer?.firstname +
                                " " +
                                userReducer?.lastname}
                          </p>
                        </div>
                        <div className="tc-place-section-box-row">
                          <h2 className="tc-place-title">District:</h2>
                          <p className="tc-place-name">{card?.md_location}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </>
          )}
          {/* QTY card modal */}
          {showQtyModal && (
            <MainModal
              show={showQtyModal}
              title={moment().format("LL")}
              onHide={() => setShowQtyModal(false)}
              data={cardUsagesByMDList}
            />
          )}

          {/* patient card moadl */}
          {showPatientModal && (
            <CardModal
              show={showPatientModal}
              onHide={() => handlePatientModal()}
              carddata={selectedCard}
              patientdata={patientReducer?.patientDetails}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
