import { configureStore } from "@reduxjs/toolkit";

// Slice
import userSlice from "./slice/userSlice";
import cardSlice from "./slice/cardSlice";
import patientSlice from "./slice/patientSlice";
import weeklyReportSlice from "./slice/weeklyReportSlice";
import monthlyReportSlice from "./slice/monthlyReportSlice";
import dailyReportSlice from "./slice/dailyReportSlice";
import extrapolationSlice from "./slice/extrapolationSlice";
import productReportSlice from "./slice/productReportSlice";
import cardAssignSlice from "./slice/cardAssignSlice";

const store = configureStore({
  reducer: {
    userReducer: userSlice,
    cardReducer: cardSlice,
    patientReducer: patientSlice,
    productReportReducer: productReportSlice,
    dailyReportReducer: dailyReportSlice,
    weeklyReportReducer: weeklyReportSlice,
    monthlyReportReducer: monthlyReportSlice,
    extrapolationReducer: extrapolationSlice,
    cardAssignReducer: cardAssignSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
