import React, { useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { routes } from "../../helpers/constants";
import { reqToUserLogout } from "../../store/slice/userSlice";
import { useDispatch } from "react-redux";

const MenuButton = (props) => {
  const { handleCardAssignShow } = props;
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);

  // Handle click events outside of menu button
  useEffect(() => {
    document.onclick = (event) => {
      if (event.target.className !== "navbar-toggler-icon") {
        setMenuOpen(false);
      }
    };
  }, []);

  const handleClick = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Navbar>
      <button
        type="button"
        className="navbar-toggler d-block"
        onClick={(e) => handleClick(e)}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <ul className={`${menuOpen && "open"}`}>
        <li className="nav-item">
          <Link to={routes.home} className="nav-link">
            Home
          </Link>
        </li>
        <li className="nav-item">
          <div onClick={() => handleCardAssignShow()} className="nav-link">
            Assign Card
          </div>
        </li>
        <li className="nav-item">
          <Link to={routes.home} className="nav-link">
            Settings
          </Link>
        </li>
        <li className="nav-item">
          <Link
            onClick={() => dispatch(reqToUserLogout())}
            className="nav-link"
          >
            Sign Out
          </Link>
        </li>
      </ul>
    </Navbar>
  );
};

export default MenuButton;
