import React from "react";
import day from "../../images/days.png";
import week from "../../images/week.png";
import month from "../../images/month.png";
import { useLocation } from "react-router-dom";

const ProductSecFooter = (props) => {
  const { showProductReport, handleShowProductReports } = props;
  const location = useLocation();
  return (
    <>
      {location.pathname === "/product" && (
        <div className="tc-product-footer">
          <div
            className={`tc-product-footer-content ${
              showProductReport?.isdisable && "tc-footer-tab-disabled"
            }`}
          >
            <div
              className="tc-footer-tab"
              onClick={() => {
                handleShowProductReports("daily", "", true);
              }}
            >
              <img src={day} alt="" />
              daily
            </div>
            <div
              className="tc-footer-tab"
              onClick={() => {
                handleShowProductReports("weekly", "", true);
              }}
            >
              <img src={week} alt="" />
              weekly
            </div>
            <div
              className="tc-footer-tab"
              onClick={() => {
                handleShowProductReports("monthly", "", true);
              }}
            >
              <img src={month} alt="" />
              monthly
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductSecFooter;
