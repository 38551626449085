import React from "react";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";

import dataNotFound from "../../../images/DataNotFound.svg";
import { numberFormating } from "../../../helpers/commonFunction";

const RioReport = (props) => {
  const {
    showExtrapolation,
    selectedExtrapolationMD,
    handleExtrapolationModal,
  } = props;

  // Get extrapolation reducer data
  const extrapolationReducer = useSelector(
    (state) => state.extrapolationReducer
  );
  const productList = extrapolationReducer.extrapolationProductList;

  return (
    <div
      className={`tc-rio-section tc-sidebar-section ${
        showExtrapolation && "tc-rio-sidebar"
      }`}
    >
      <div className="tc-product-navbar">
        <button
          type="button"
          className="tc-product-menu"
          onClick={() => handleExtrapolationModal("product")}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" />
          </svg>
        </button>
        <div className="tc-product-text">
          <h2>Rio Report</h2>
        </div>
      </div>
      <div className="tc-product-body">
        <div className="tc-body-content">
          <h1 className="tc-main-title">
            {" "}
            {selectedExtrapolationMD &&
              selectedExtrapolationMD?.Doctor?.firstname.concat(
                " " + selectedExtrapolationMD?.Doctor?.lastname
              )}
          </h1>
          <Table className="tc-table tc-daily-table tc-weekly-table">
            <thead>
              <tr>
                <th>PRODUCT</th>
                <th>ea</th>
              </tr>
            </thead>
            <tbody>
              {productList && productList.length === 0 ? (
                <tr>
                  <td colSpan="2">
                    <div className="tc-datanot-found">
                      <img src={dataNotFound} alt="Data Not Found" />
                      <p>Data not found</p>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {productList &&
                    productList.map((product, index) => {
                      return (
                        <tr key={index}>
                          <td>{product?.Product?.name}</td>
                          <td className="tc-text-success">
                            {numberFormating(product?.ext_amount)}
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </Table>
          <Table className="tc-totle-table">
            <tbody>
              <tr>
                <td>Total EA:</td>
                <td className="tc-text-success">
                  {numberFormating(
                    selectedExtrapolationMD && selectedExtrapolationMD?.total_ea
                  )}
                </td>
              </tr>
              <tr>
                <td>Budget:</td>
                <td>
                  {numberFormating(
                    selectedExtrapolationMD && selectedExtrapolationMD?.budget
                  )}
                </td>
              </tr>
              <tr>
                <td>ROI</td>
                <td className="tc-text-success">
                  {selectedExtrapolationMD && selectedExtrapolationMD?.roi}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default RioReport;
