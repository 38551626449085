import React from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";

import { routes } from "../../helpers/constants";
import dataNotFound from "../../images/DataNotFound.svg";

const WeeklyMdDetails = (props) => {
  const {
    showMDList,
    userReducer,
    selectedWeekData,
    handleShowMDList,
    getWeeklyReportCardList,
  } = props;
  const navigate = useNavigate();

  // Get weekly report reducer data
  const weeklyReportReducer = useSelector((state) => state.weeklyReportReducer);
  const weeklyReportMDList = weeklyReportReducer?.weeklyReportMDList;

  return (
    <div className={`tc-sidebar-section ${showMDList && "zl-sidebar-open"}`}>
      <div className="tc-navbar-section tc-report-navbar">
        <div className="tc-navbar-content">
          <div className="tc-navbar-menu">
            <div className="tc-navbar-back" onClick={() => handleShowMDList()}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" />
              </svg>
            </div>
          </div>
          <div className="tc-navbar-text">
            <h2>
              {userReducer &&
                userReducer?.firstname.concat(" " + userReducer?.lastname)}
            </h2>
          </div>
          <div
            className="tc-navbar-icon"
            onClick={() => navigate(routes.product)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5.99999 2.82349C5.41521 2.82349 4.94116 3.29754 4.94116 3.88231V5.82349C4.94116 6.40826 5.41521 6.88231 5.99999 6.88231H18C18.5848 6.88231 19.0588 6.40826 19.0588 5.82349V3.88231C19.0588 3.29754 18.5848 2.82349 18 2.82349H5.99999Z" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.35293 7.41172H17.8235V18.3529C17.8235 19.9123 16.5594 21.1764 15 21.1764H9.17646C7.61707 21.1764 6.35293 19.9123 6.35293 18.3529V7.41172ZM13.2353 10.4117H10.7647V12.5294H8.64704V15H10.7647V17.1176H13.2353V15H15.3529V12.5294H13.2353V10.4117Z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="tc-weekly-content">
        <h1 className="tc-main-title">
          Week {selectedWeekData && selectedWeekData?.week_no}
        </h1>
        <div className="tc-daily-number">
          <p className="tc-number-text">
            <span>{selectedWeekData && selectedWeekData?.weekDate}</span>
          </p>
          <p className="tc-number-text">
            <span>Total:</span>{" "}
            <span className="tc-text-success">
              {" "}
              ₽{selectedWeekData && selectedWeekData?.card_total}
            </span>
          </p>
        </div>
        <Table className="tc-table tc-daily-table tc-weekly-table">
          <thead>
            <tr>
              <th>md name</th>
              <th>#</th>
              <th>₽</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {weeklyReportMDList && weeklyReportMDList.length === 0 ? (
              <tr>
                <td colSpan="4">
                  <div className="tc-datanot-found">
                    <img src={dataNotFound} alt="Data Not Found" />
                    <p>Data not found</p>
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {weeklyReportMDList &&
                  weeklyReportMDList.map((mdData, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {mdData?.firstname.concat(" " + mdData?.lastname)}
                        </td>
                        <td>{mdData?.qty}</td>
                        <td>{mdData?.card_total}</td>
                        <td>
                          <div
                            className="tc-sidebar-btn"
                            onClick={() => getWeeklyReportCardList(mdData)}
                          >
                            <FontAwesomeIcon icon={faCircleArrowRight} />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default WeeklyMdDetails;
