import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";

// Get MD list of my territory
export const reqToGetTerritoryMDList = createAsyncThunk(
  "reqToGetTerritoryMDList",
  async ({ onSuccess }) => {
    try {
      const response = await Axios.get(
        "/api/v1/card/md-list-by-territory",
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Assign card to MD
export const reqToAssignCard = createAsyncThunk(
  "reqToAssignCard",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/card/assign-to-md",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Initial state
const initialState = {
  loader: false,
  error: "",
  territoryMDList: [],
};

// Create slice
const cardAssignSlice = createSlice({
  name: "cardAssign",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // MD list of my territory
    builder.addCase(reqToGetTerritoryMDList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetTerritoryMDList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.territoryMDList = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetTerritoryMDList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Assign card
    builder.addCase(reqToAssignCard.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToAssignCard.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToAssignCard.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default cardAssignSlice.reducer;
