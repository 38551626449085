import React from "react";
import moment from "moment";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { routes } from "../../helpers/constants";
import dataNotFound from "../../images/DataNotFound.svg";

const WeeklyCardDetails = (props) => {
  const {
    showCardList,
    userReducer,
    handleShowCardList,
    selectedWeekData,
    selectedMDData,
    selectedProductData,
    getWeeklyReportProductList,
  } = props;
  const navigate = useNavigate();

  // Get daily report reducer data
  const weeklyReportReducer = useSelector((state) => state.weeklyReportReducer);
  const weeklyReportCardList = weeklyReportReducer?.weeklyReportCardList;
  const weeklyReportProductList = weeklyReportReducer?.weeklyReportProductList;

  return (
    <div className={`tc-sidebar-section ${showCardList && "zl-sidebar-open"}`}>
      <div className="tc-navbar-section tc-report-navbar">
        <div className="tc-navbar-content">
          <div className="tc-navbar-menu">
            <div
              className="tc-navbar-back"
              onClick={() => handleShowCardList()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" />
              </svg>
            </div>
          </div>
          <div className="tc-navbar-text">
            <h2>
              {userReducer &&
                userReducer?.firstname.concat(" " + userReducer?.lastname)}
            </h2>
          </div>
          <div
            className="tc-navbar-icon"
            onClick={() => navigate(routes.product)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5.99999 2.82349C5.41521 2.82349 4.94116 3.29754 4.94116 3.88231V5.82349C4.94116 6.40826 5.41521 6.88231 5.99999 6.88231H18C18.5848 6.88231 19.0588 6.40826 19.0588 5.82349V3.88231C19.0588 3.29754 18.5848 2.82349 18 2.82349H5.99999Z" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.35293 7.41172H17.8235V18.3529C17.8235 19.9123 16.5594 21.1764 15 21.1764H9.17646C7.61707 21.1764 6.35293 19.9123 6.35293 18.3529V7.41172ZM13.2353 10.4117H10.7647V12.5294H8.64704V15H10.7647V17.1176H13.2353V15H15.3529V12.5294H13.2353V10.4117Z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="tc-weekly-content">
        <h1 className="tc-main-title">
          {selectedMDData?.firstname.concat(", " + selectedMDData?.lastname)}
        </h1>
        <div className="tc-daily-number">
          <p className="tc-number-text">
            <span>{selectedWeekData && selectedWeekData?.weekDate}</span>
          </p>
          <p className="tc-number-text tc-number">
            <span>
              #:{" "}
              <span className="tc-text-success">
                {" "}
                {selectedMDData && selectedMDData?.qty}
              </span>
            </span>
            <span>
              ₽:
              <span className="tc-text-success">
                ₽{selectedMDData && selectedMDData?.card_total}
              </span>
            </span>
          </p>
        </div>
        <Table className="tc-table tc-daily-table">
          <thead>
            <tr>
              <th>tc serial</th>
              <th>amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {weeklyReportCardList && weeklyReportCardList.length === 0 ? (
              <tr>
                <td colSpan="3">
                  <div className="tc-datanot-found">
                    <img src={dataNotFound} alt="Data Not Found" />
                    <p>Data not found</p>
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {weeklyReportCardList &&
                  weeklyReportCardList.map((cardData, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>{cardData?.serial_no}</td>
                          <td>{cardData?.card_total}</td>
                          <td>
                            <div
                              className="tc-menu-btn"
                              onClick={() =>
                                getWeeklyReportProductList(cardData)
                              }
                            >
                              <FontAwesomeIcon icon={faEllipsis} />
                            </div>
                          </td>
                        </tr>
                        {weeklyReportProductList &&
                        weeklyReportProductList.length === 0 &&
                        selectedProductData?.card_id === cardData?.card_id ? (
                          <tr>
                            <td colSpan="3">
                              <div className="tc-datanot-found">
                                <img src={dataNotFound} alt="Data Not Found" />
                                <p>Data not found</p>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {selectedProductData?.card_id ===
                              cardData?.card_id &&
                              weeklyReportProductList?.map((product, key) => {
                                return (
                                  <tr
                                    key={key}
                                    className={`tc-hide-row-weekly tc-weekly-table-sub-data tc-hide-row ${
                                      selectedProductData?.card_id ===
                                        cardData?.card_id && "zl-show-row"
                                    }`}
                                  >
                                    <td colSpan="3">
                                      <div className="tc-weekly-data">
                                        <div className="tc-data-date">
                                          {key > 0 &&
                                          weeklyReportProductList[key - 1]
                                            .p_date !== product?.p_date
                                            ? moment(product?.p_date).format(
                                                "DD/MM/YYYY"
                                              )
                                            : key === 0 &&
                                              moment(product?.p_date).format(
                                                "DD/MM/YYYY"
                                              )}
                                        </div>
                                        <div className="tc-data-date">
                                          {product?.name}
                                        </div>
                                        <div className="tc-data-date">
                                          {product?.product_total}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </>
                        )}
                      </>
                    );
                  })}
              </>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default WeeklyCardDetails;
