import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";

// Get product list
export const reqToGetProductList = createAsyncThunk(
  "reqToGetProductList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/product-reports/all",
        data,
        authHeaders()
      );
      onSuccess(response.data?.product_list);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get product details
export const reqToGetProductDetails = createAsyncThunk(
  "reqToGetProductDetails",
  async ({ data }) => {
    try {
      const response = await Axios.post(
        "/api/v1/product-reports/product-details",
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get daily product report data
export const reqToGetDProdReportData = createAsyncThunk(
  "reqToGetDProdReportData",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/product-reports/daily",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get day wise product report MD list
export const reqToGetDProductMDList = createAsyncThunk(
  "reqToGetDProductMDList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/product-reports/daily/md-list",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get day wise product report card list
export const reqToGetDProductCardList = createAsyncThunk(
  "reqToGetDProductCardList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/product-reports/daily/card-list",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get weekly product report data
export const reqToGetWProdReportData = createAsyncThunk(
  "reqToGetWProdReportData",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/product-reports/weekly",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get week wise product report MD list
export const reqToGetWProductMDList = createAsyncThunk(
  "reqToGetWProductMDList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/product-reports/weekly/md-list",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get week wise product report card list
export const reqToGetWProductCardList = createAsyncThunk(
  "reqToGetWProductCardList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/product-reports/weekly/card-list",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get monthly product report data
export const reqToGetMProdReportData = createAsyncThunk(
  "reqToGetMProdReportData",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/product-reports/monthly",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get month wise product report MD list
export const reqToGetMProductMDList = createAsyncThunk(
  "reqToGetMProductMDList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/product-reports/monthly/md-list",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get month wise product report card list
export const reqToGetMProductCardList = createAsyncThunk(
  "reqToGetMProductCardList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/product-reports/monthly/card-list",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Create initial state
const initialState = {
  loader: false,
  error: "",
  productList: [],
  productDetails: [],

  dailyProdReportData: [],
  dailyProdReportMDList: [],
  dailyProdReportCardList: [],

  weeklyProdReportData: [],
  weeklyProdReportMDList: [],
  weeklyProdReportCardList: [],

  monthlyProdReportData: [],
  monthlyProdReportMDList: [],
  monthlyProdReportCardList: [],
};

// Create product report slice
const productReportSlice = createSlice({
  name: "productReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get product list data =========================================
    builder.addCase(reqToGetProductList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetProductList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.productList = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetProductList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get product details =============================================
    builder.addCase(reqToGetProductDetails.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetProductDetails.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.productDetails = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetProductDetails.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Daily product report data =========================================
    builder.addCase(reqToGetDProdReportData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetDProdReportData.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.dailyProdReportData = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetDProdReportData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Day wise product report MD list ======================================
    builder.addCase(reqToGetDProductMDList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetDProductMDList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.dailyProdReportMDList = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetDProductMDList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Day wise product report card list ======================================
    builder.addCase(reqToGetDProductCardList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetDProductCardList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.dailyProdReportCardList = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetDProductCardList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Weekly product report data =============================================
    builder.addCase(reqToGetWProdReportData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetWProdReportData.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.weeklyProdReportData = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetWProdReportData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Week wise product report MD list ======================================
    builder.addCase(reqToGetWProductMDList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetWProductMDList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.weeklyProdReportMDList = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetWProductMDList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Week wise product report card list ====================================
    builder.addCase(reqToGetWProductCardList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetWProductCardList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.weeklyProdReportCardList = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetWProductCardList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Monthly product report data =============================================
    builder.addCase(reqToGetMProdReportData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetMProdReportData.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.monthlyProdReportData = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetMProdReportData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Month wise product report MD list =======================================
    builder.addCase(reqToGetMProductMDList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetMProductMDList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.monthlyProdReportMDList = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetMProductMDList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Month wise product report card list =====================================
    builder.addCase(reqToGetMProductCardList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetMProductCardList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.monthlyProdReportCardList = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetMProductCardList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default productReportSlice.reducer;
