import axios from "axios";

export const authHeaders = () => {
  const authToken =
    localStorage.getItem("token") && JSON.parse(localStorage.getItem("token"));
  // const authToken =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwibGFzdF9uYW1lIjoiQXNodmluIiwiZmlyc3RfbmFtZSI6IlZhbmFuaSIsIm1vYmlsZV9ubyI6Iis5MTk4MjU2ODU2MDYiLCJpYXQiOjE2ODU2MjQ1NTEsImV4cCI6MTY4NjQ4ODU1MSwiaXNzIjoiaHR0cHM6Ly93d3cuYmVuZ2dhLmNvbSJ9.BS8fh-YcIR8i2MBzKsBeHEKVqHHVuUnI8BjwBlQqSlA";

  return {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
};

const Axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

export default Axios;
