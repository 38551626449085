import React from "react";
import {
  faCalendarDay,
  faCalendarDays,
  faCalendarWeek,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../../helpers/constants";

const Footer = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname !== "/product" && (
        <div className="tc-footer-section">
          <div className="tc-footer-content">
            <Link to={routes.dailyreport} className="tc-footer-tab">
              <FontAwesomeIcon icon={faCalendarDay} />
              daily
            </Link>
            <Link to={routes.weeklyreport} className="tc-footer-tab">
              <FontAwesomeIcon icon={faCalendarWeek} />
              weekly
            </Link>
            <Link to={routes.monthlyreport} className="tc-footer-tab">
              <FontAwesomeIcon icon={faCalendarDays} />
              monthly
            </Link>
          </div>
        </div>
      )}
    </>
  );
};
export default Footer;
