import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { reqToSendOTP, reqToVerifyOTP } from "../../store/slice/userSlice";
import { notify } from "../../helpers/commonFunction";
import { messages } from "../../helpers/commonMessages";
import Loader from "../../component/Common/Loader";
import { useEffect } from "react";
import { routes } from "../../helpers/constants";

const VerifyOtp = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const userReducer = useSelector((state) => state.userReducer);

  const [verifyotp, setVerifyotp] = useState("");
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    if (location?.state?.mobileNumber === undefined) {
      navigate(routes.login);
    }
  }, [location, navigate]);

  // Handle verify user OTP
  const handleVerifyOTP = () => {
    if (verifyotp === "") {
      notify(messages.otp_valid, "error");
    } else {
      dispatch(
        reqToVerifyOTP({
          otp: verifyotp,
          mobile_no: location?.state?.mobileNumber,
        })
      );
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  // Handle request To Resend OTP
  const requestToResendOTP = () => {
    setSeconds(30);
    dispatch(
      reqToSendOTP({
        data: { mobile_no: location?.state?.mobileNumber },
      })
    );
  };

  return (
    <>
      {userReducer.loader && <Loader />}
      <div className="tc-login-section">
        <div className="tc-login-content tc-otp-content">
          <a href="/" className="tc-logo">
            T-Card
          </a>
          <div className="tc-otp-input">
            <OtpInput
              value={verifyotp}
              onChange={setVerifyotp}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
            />
          </div>
          <h2 className="tc-otp-text">
            {seconds > 0 ? (
              <>
                Resend OTP in{" "}
                <span>00:{seconds < 10 ? "0" + seconds : seconds}</span> Seconds
              </>
            ) : (
              <>
                Don't receive code?{" "}
                <button onClick={() => requestToResendOTP()}>
                  Request again
                </button>
              </>
            )}
          </h2>
          <button className="tc-save" onClick={() => handleVerifyOTP()}>
            Submit
          </button>
          <button
            type="button"
            className="tc-back-login"
            onClick={() => navigate(-1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
            Back to log in
          </button>
        </div>
      </div>
    </>
  );
};

export default VerifyOtp;
