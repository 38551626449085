import React from "react";
import Modal from "react-bootstrap/Modal";
import { Table } from "react-bootstrap";
import { memo } from "react";

const MainModal = (props) => {
  const { data } = props;
  return (
    <Modal
      {...props}
      size="false"
      className="tc-model-dialog tc-home-model"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="tc-model-header" closeButton>
        <Modal.Title
          className="tc-model-header-title"
          id="contained-modal-title-vcenter"
        >
          TC Today
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="tc-model-body">
        <h2 className="tc-model-title">{props.title}</h2>
        <div className="tc-model-text">
          <p>Total: ₽{data?.grand_total}</p>
          <p>No. of Card: {data?.cards_qty}</p>
        </div>
        <Table className="tc-table">
          <thead>
            <tr>
              <th>MD NAME</th>
              <th>QTY</th>
              <th>AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data?.md_list?.map((mdCards, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {mdCards?.md_firstname + " " + mdCards?.md_lastname}
                    </td>
                    <td>{mdCards.qty}</td>
                    <td>{mdCards.md_total}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default memo(MainModal);
