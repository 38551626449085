import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Table } from "react-bootstrap";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MonthlyMdDetails from "./MonthlyMdDetails";
import MonthlyCardDetails from "./MonthlyCardDetails";
import { useDispatch, useSelector } from "react-redux";
import { getStartDateAndEndDate } from "../../helpers/commonFunction";

import { useEffect } from "react";
import moment from "moment";
import Loader from "../../component/Common/Loader";
import dataNotFound from "../../images/DataNotFound.svg";

// API
import {
  reqToGetMonthWiseCardList,
  reqToGetMonthWiseMDList,
  reqToGetMonthWiseProductList,
  reqToGetMonthlyReportData,
} from "../../store/slice/monthlyReportSlice";

const MonthlyReport = () => {
  const dispatch = useDispatch();
  const date = new Date();
  const userReducer =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  // Get monthly reducer data
  const monthlyReportReducer = useSelector(
    (state) => state.monthlyReportReducer
  );
  const monthlyReportData =
    monthlyReportReducer && monthlyReportReducer?.monthlyReportData;

  // Default month data of yesr
  const initialState = [
    { month_no: 1, qty: 0, month_name: "Jan", card_total: 0, isVisible: false },
    { month_no: 2, qty: 0, month_name: "Feb", card_total: 0, isVisible: false },
    { month_no: 3, qty: 0, month_name: "Mar", card_total: 0, isVisible: false },
    { month_no: 4, qty: 0, month_name: "Apr", card_total: 0, isVisible: false },
    { month_no: 5, qty: 0, month_name: "May", card_total: 0, isVisible: false },
    { month_no: 6, qty: 0, month_name: "Jun", card_total: 0, isVisible: false },
    { month_no: 7, qty: 0, month_name: "Jul", card_total: 0, isVisible: false },
    { month_no: 8, qty: 0, month_name: "Aug", card_total: 0, isVisible: false },
    { month_no: 9, qty: 0, month_name: "Sep", card_total: 0, isVisible: false },
    {
      month_no: 10,
      qty: 0,
      month_name: "Oct",
      card_total: 0,
      isVisible: false,
    },
    {
      month_no: 11,
      qty: 0,
      month_name: "Nov",
      card_total: 0,
      isVisible: false,
    },
    {
      month_no: 12,
      qty: 0,
      month_name: "Dec",
      card_total: 0,
      isVisible: false,
    },
  ];

  const [totalCardAmount, setTotalCardAmount] = useState(0);
  const [reportDataArray, setReportDataArray] = useState(initialState);

  // Sidebar states
  const [showMDList, setShowMDList] = useState(false);
  const [showCardList, setShowCardList] = useState(false);

  // Selected data states
  const [selectedMonthData, setSelectedMonthData] = useState();
  const [selectedMDData, setSelectedMDData] = useState();
  const [selectedProductData, setSelectedProductData] = useState();
  const [monthlyChartData, setMonthlyChartData] = useState({
    options: {
      chart: {
        type: "bar",
        height: 250,
        toolbar: {
          show: false,
        },
      },
      colors: ["#96da7a"],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["#000"],
      },
      xaxis: {
        categories: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
      },
      grid: {
        show: false,
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: false,
      },
      series: [
        {
          name: "Series 1",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      noData: {
        text: "Data not found",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -30,
        style: {
          color: "#000",
          fontSize: "16px",
          fontFamily: "Cabin, sans-serif",
        },
      },
    },
  });

  // Handle MD list sidebar
  const handleShowMDList = () => {
    setShowMDList(!showMDList);
  };

  // Handle card list sidebar
  const handleShowCardList = () => {
    setShowCardList(!showCardList);
  };

  // Get month card data
  useEffect(() => {
    dispatch(
      reqToGetMonthlyReportData({
        data: {
          report_date: moment().format("YYYY-MM-DD"),
        },
        onSuccess: (response) => {
          if (response && response.length > 0) {
            updateMonthlyReportData(response);
          }
        },
      })
    );
  }, [dispatch]);

  // Update monthly report chart data
  useEffect(() => {
    let data = reportDataArray.map((item) => item.qty);
    let checkData = reportDataArray.every((item) => item.qty === 0);
    setMonthlyChartData({
      ...monthlyChartData,
      options: {
        ...monthlyChartData.options,
        series: [
          {
            name: "Card",
            data: checkData === false ? data : [],
          },
        ],
      },
    });
  }, [reportDataArray]);

  // Update reportDataArray data and Count total card amount
  const updateMonthlyReportData = (data) => {
    let count = 0;
    for (let i = 0; i < data.length; i++) {
      setReportDataArray((prevState) => {
        const newState = prevState.map((prev) => {
          if (prev.month_no === data[i].month_no) {
            return { ...data[i], isVisible: true };
          }
          return prev;
        });
        return newState;
      });

      count = count + data[i]?.card_total;
      setTotalCardAmount(count);
    }
  };

  // Get MD data by selected date
  const getMonthlyReportMDList = (item) => {
    const getDates = getStartDateAndEndDate("monthDates", "", item.month_name);
    setSelectedMonthData({
      ...item,
      start_date: moment(getDates.startDate).format("YYYY-MM-DD"),
      end_date: moment(getDates.endDate).format("YYYY-MM-DD"),
      year: date.getFullYear(),
    });
    dispatch(
      reqToGetMonthWiseMDList({
        data: {
          start_date: moment(getDates.startDate).format("YYYY-MM-DD"),
          end_date: moment(getDates.endDate).format("YYYY-MM-DD"),
        },
        onSuccess: () => {
          handleShowMDList();
        },
      })
    );
  };

  // Get card details by selected MD
  const getMonthlyReportCardList = (item) => {
    setSelectedMDData(item);
    dispatch(
      reqToGetMonthWiseCardList({
        data: {
          doctor: item?.doctor_id,
          start_date: selectedMonthData.start_date,
          end_date: selectedMonthData.end_date,
        },
        onSuccess: () => {
          handleShowCardList();
          setSelectedProductData("");
        },
      })
    );
  };

  // Get product details by selected card
  const getMonthlyReportProductList = (item) => {
    if (selectedProductData?.card_id !== item?.card_id) {
      dispatch(
        reqToGetMonthWiseProductList({
          data: {
            start_date: selectedMonthData.start_date,
            end_date: selectedMonthData.end_date,
            card: item.card_id,
          },
          onSuccess: () => {
            setSelectedProductData(item);
          },
        })
      );
    } else {
      setSelectedProductData("");
    }
  };

  return (
    <>
      {monthlyReportReducer && monthlyReportReducer?.loader && <Loader />}
      <div className="tc-body-section">
        <div className="tc-body-content">
          <h1 className="tc-main-title">TCard per Monthly</h1>
          <div className="tc-daily-number tc-weekly-number">
            <div className="tc-select-box">as of {moment().format("LL")}</div>
            <p className="tc-number-text tc-number">
              <span className="tc-text-success">
                {" "}
                ₽{totalCardAmount && totalCardAmount}
              </span>
            </p>
          </div>
          <div className="tc-daily-chart">
            <ReactApexChart
              options={monthlyChartData?.options}
              series={monthlyChartData?.options?.series}
              type="bar"
              height={250}
            />
          </div>
          <Table className="tc-table tc-daily-table tc-weekly-table">
            <thead>
              <tr>
                <th>month</th>
                <th>#</th>
                <th>₽</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {monthlyReportData && monthlyReportData.length === 0 ? (
                <tr>
                  <td colSpan="4">
                    <div className="tc-datanot-found">
                      <img src={dataNotFound} alt="Data Not Found" />
                      <p>Data not found</p>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {reportDataArray &&
                    reportDataArray?.map((monthReport, index) => {
                      return (
                        <tr
                          key={index}
                          className={`${
                            monthReport?.isVisible === false &&
                            "tc-table-row-disabled"
                          }`}
                        >
                          <td>{monthReport?.month_name}</td>
                          <td>{monthReport?.qty}</td>
                          <td>{monthReport?.card_total}</td>
                          <td>
                            <div
                              className="tc-sidebar-btn"
                              onClick={() =>
                                getMonthlyReportMDList(monthReport)
                              }
                            >
                              <FontAwesomeIcon icon={faCircleArrowRight} />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </Table>

          {/* Weekly report MD list sidebar*/}
          <MonthlyMdDetails
            showMDList={showMDList}
            userReducer={userReducer}
            selectedMonthData={selectedMonthData}
            handleShowMDList={handleShowMDList}
            getMonthlyReportCardList={getMonthlyReportCardList}
          />

          {/* Weekly report card list sidebar*/}
          <MonthlyCardDetails
            showCardList={showCardList}
            userReducer={userReducer}
            handleShowCardList={handleShowCardList}
            selectedMonthData={selectedMonthData}
            selectedMDData={selectedMDData}
            selectedProductData={selectedProductData}
            getMonthlyReportProductList={getMonthlyReportProductList}
          />
        </div>
      </div>
    </>
  );
};

export default MonthlyReport;
