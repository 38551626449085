import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { messages } from "../../helpers/commonMessages";

// Send OTP
export const reqToSendOTP = createAsyncThunk(
  "reqToSendOTP",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post("/api/v1/send-otp", data);
      onSuccess(response);
      return response;
    } catch (error) {
      if (error.response.data === "Failed to send OTP") {
        notify(error.response.data, "error");
      }
      return error;
    }
  }
);

// Verify OTP
export const reqToVerifyOTP = createAsyncThunk(
  "reqToVerifyOTP",
  async (data) => {
    try {
      const response = await Axios.post("/api/v1/verify-otp", data);
      return response;
    } catch (error) {
      notify(error.response.data, "error");
      return error.response;
    }
  }
);

// Create initial state
const initialUser = localStorage.getItem("user")
  ? localStorage.getItem("user")
  : null;
const initialState = {
  loader: false,
  user: initialUser,
  error: "",
};

// Create user slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reqToUserLogout: (state) => {
      state.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      notify(messages.logout, "success");
    },
  },
  extraReducers: (builder) => {
    // Send OTP
    builder.addCase(reqToSendOTP.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToSendOTP.fulfilled, (state, action) => {
      if (action.payload.status === 200) {
        state.loader = false;
        notify(action.payload.data.message, "success");
      } else {
        state.loader = false;
        notify(action.payload.response.data, "error");
      }
    });
    builder.addCase(reqToSendOTP.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Verify OTP
    builder.addCase(reqToVerifyOTP.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToVerifyOTP.fulfilled, (state, action) => {
      state.loader = false;
      if (action.payload.status === 200) {
        state.user = action.payload.data;
        notify(messages.login, "success");
        const authToken = action.payload.headers["authorization"];
        localStorage.setItem("token", JSON.stringify(authToken));
        localStorage.setItem("user", JSON.stringify(action.payload.data));
      }
    });
    builder.addCase(reqToVerifyOTP.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export const { reqToUserLogout } = userSlice.actions;
export default userSlice.reducer;
