import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";

// Get patient
export const reqToGetPatientList = createAsyncThunk(
  "reqToGetPatientList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.get(
        `/api/v1/card/patient-details?patient=${data.pid}&card=${data.cid}`,
        authHeaders()
      );
      onSuccess(response);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Create initial state
const initialState = {
  loader: false,
  patientDetails: [],
  error: "",
};

// Create patient details Slice
const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get patient
    builder.addCase(reqToGetPatientList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetPatientList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.patientDetails = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status === 500
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetPatientList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default patientSlice.reducer;
