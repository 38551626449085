import React from "react";
import { Navigate } from "react-router-dom";
import { routes } from "./helpers/constants";

// Layouts
import Layout from "./component/Layout/MainLayout";
import AuthPage from "./component/Layout/AuthPage";

// Auth pages
import SendOtp from "./pages/Login/SendOtp";
import VerifyOtp from "./pages/Login/VerifyOtp";

// Comman pages
import Home from "./pages/Home/Home";
import Product from "./pages/Product/Product";
import DailyReport from "./pages/TCardDailyReport/DailyReport";
import WeeklyReport from "./pages/TCardWeeklyReport/WeeklyReport";
import MonthlyReport from "./pages/TCardMonthlyReport/MonthlyReport";

const getRoutes = (user) => [
  {
    path: routes.homepage,
    element: !user ? <AuthPage /> : <Navigate to={routes.home} />,
    children: [
      { path: routes.homepage, element: <Navigate to={routes.login} /> },
      { path: routes.login, element: <SendOtp /> },
      { path: routes.verifyotp, element: <VerifyOtp /> },
    ],
  },
  {
    path: routes.homepage,
    element: user ? <Layout /> : <Navigate to={routes.login} />,
    children: [
      { path: routes.home, element: <Home /> },
      { path: routes.product, element: <Product /> },
      { path: routes.dailyreport, element: <DailyReport /> },
      { path: routes.weeklyreport, element: <WeeklyReport /> },
      { path: routes.monthlyreport, element: <MonthlyReport /> },
    ],
  },
];

export default getRoutes;
