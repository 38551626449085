import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import dataNotFound from "../../../images/DataNotFound.svg";

const DailyCardDetails = (props) => {
  const {
    showCardList,
    selectedProduct,
    selectedMDData,
    handleShowCardList,
    selectedReportData,
  } = props;

  // Get daily product report reducer data
  const prodReportReducer = useSelector((state) => state.productReportReducer);
  const dailyProdReportCardList = prodReportReducer?.dailyProdReportCardList;

  return (
    <div
      className={`tc-sidebar-section tc-product-cardlist-sidebar ${
        showCardList && "zl-sidebar-open"
      }`}
    >
      <div className="tc-navbar-section tc-report-navbar">
        <div className="tc-navbar-content">
          <div className="tc-navbar-menu">
            <div
              className="tc-navbar-back"
              onClick={() => handleShowCardList("daily")}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" />
              </svg>
            </div>
          </div>
          <div className="tc-product-text">
            <h2>{selectedProduct && selectedProduct?.name}</h2>
          </div>
        </div>
      </div>
      <div className="tc-body-content">
        <h1 className="tc-main-title">
          {selectedMDData &&
            selectedMDData?.firstname.concat(", " + selectedMDData?.lastname)}
        </h1>
        <div className="tc-daily-number">
          <p className="tc-number-text">
            <span>{moment(selectedReportData?.p_date).format("LL")}</span>
          </p>
          <p className="tc-number-text">
            {" "}
            <span>Total:</span>{" "}
            <span className="tc-text-success">
              {" "}
              ₽ {selectedReportData && selectedReportData?.card_total}
            </span>
          </p>
        </div>
        <Table className="tc-table tc-daily-table">
          <thead>
            <tr>
              <th>tc serial</th>
              <th>amount</th>
            </tr>
          </thead>
          <tbody>
            {dailyProdReportCardList && dailyProdReportCardList.length === 0 ? (
              <tr>
                <td colSpan="2">
                  <div className="tc-datanot-found">
                    <img src={dataNotFound} alt="Data Not Found" />
                    <p>Data not found</p>
                  </div>
                </td>
              </tr>
            ) : (
              dailyProdReportCardList &&
              dailyProdReportCardList?.map((cardData, index) => {
                return (
                  <tr key={index}>
                    <td>{cardData?.serial_no}</td>
                    <td>{cardData?.card_total}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default DailyCardDetails;
