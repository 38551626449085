import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";

// Get weekly report data
export const reqToGetWeeklyReportData = createAsyncThunk(
  "reqToGetWeeklyReportData",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/card-reports/weekly",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get week wise MD list
export const reqToGetWeekWiseMDList = createAsyncThunk(
  "reqToGetWeekWiseMDList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/card-reports/weekly/md-list",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get week wise card list
export const reqToGetWeekWiseCardList = createAsyncThunk(
  "reqToGetWeekWiseCardList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/card-reports/weekly/card-list",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get week wise product list
export const reqToGetWeekWiseProductList = createAsyncThunk(
  "reqToGetWeekWiseProductList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/card-reports/weekly/product-list",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Create initial state
const initialState = {
  loader: false,
  error: "",
  weeklyReportData: [],
  weeklyReportMDList: [],
  weeklyReportCardList: [],
  weeklyReportProductList: [],
};

// Create weekly report slice
const weeklyReportSlice = createSlice({
  name: "weeklyReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Weekly report data
    builder.addCase(reqToGetWeeklyReportData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetWeeklyReportData.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.weeklyReportData = action.payload.data;
        state.cardUsagesByMDList = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetWeeklyReportData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Weekly report MD list
    builder.addCase(reqToGetWeekWiseMDList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetWeekWiseMDList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.weeklyReportMDList = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetWeekWiseMDList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Weekly report card list
    builder.addCase(reqToGetWeekWiseCardList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetWeekWiseCardList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.weeklyReportCardList = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetWeekWiseCardList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Weekly report product list
    builder.addCase(reqToGetWeekWiseProductList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetWeekWiseProductList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.weeklyReportProductList = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetWeekWiseProductList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default weeklyReportSlice.reducer;
