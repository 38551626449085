import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";

// For get card usages List
export const reqToGetCardList = createAsyncThunk(
  "reqToGetCardList",
  async (data) => {
    try {
      const response = await Axios.post(
        "/api/v1/card/card-usage-list",
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// For get card usages by MD // 16,17,18,29
export const reqToGetCardUsagesByMD = createAsyncThunk(
  "reqToGetCardUsagesByMD",
  async (data) => {
    try {
      const response = await Axios.post(
        "/api/v1/card/card-usage-by-md",
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Create initialState
const initialState = {
  loader: false,
  error: "",
  cardsDetails: [],
  cardUsagesByMDList: [],
};

// Create slice
const cardSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get card usages List
    builder.addCase(reqToGetCardList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetCardList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.cardsDetails = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetCardList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get card usages by MD
    builder.addCase(reqToGetCardUsagesByMD.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetCardUsagesByMD.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.cardUsagesByMDList = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetCardUsagesByMD.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error;
    });
  },
});

export default cardSlice.reducer;
