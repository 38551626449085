import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";

// Get daily report data
export const reqToGetDailyReportData = createAsyncThunk(
  "reqToGetDailyReportData",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/card-reports/daily",
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get day wise MD list
export const reqToGetDayWiseMDList = createAsyncThunk(
  "reqToGetDayWiseMDList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/card-reports/daily/md-list",
        data,
        authHeaders()
      );
      onSuccess(response);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get day wise card list
export const reqToGetDayWiseCardList = createAsyncThunk(
  "reqToGetDayWiseCardList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/card-reports/daily/card-list",
        data,
        authHeaders()
      );
      onSuccess(response);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get day wise product list
export const reqToGetDayWiseProductList = createAsyncThunk(
  "reqToGetDayWiseProductList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        "/api/v1/card-reports/daily/product-list",
        data,
        authHeaders()
      );
      onSuccess(response);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Create initial state
const initialState = {
  loader: false,
  error: "",
  dailyReportData: [],
  dailyReportMDList: [],
  dailyReportCardList: [],
  dailyReportProductList: [],
};

// Create daily report slice
const dailyReportSlice = createSlice({
  name: "dailyReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Daily report data
    builder.addCase(reqToGetDailyReportData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetDailyReportData.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.dailyReportData = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetDailyReportData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Daily report MD list
    builder.addCase(reqToGetDayWiseMDList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetDayWiseMDList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.dailyReportMDList = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetDayWiseMDList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Daily report card list
    builder.addCase(reqToGetDayWiseCardList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetDayWiseCardList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.dailyReportCardList = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetDayWiseCardList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Daily report product list
    builder.addCase(reqToGetDayWiseProductList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetDayWiseProductList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.dailyReportProductList = action.payload.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetDayWiseProductList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default dailyReportSlice.reducer;
