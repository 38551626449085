import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";

import dataNotFound from "../../../images/DataNotFound.svg";
import { getStartDateAndEndDate } from "../../../helpers/commonFunction";

const WeeklyReport = (props) => {
  const {
    selectedProduct,
    isVisibleReport,
    totalCardAmount,
    reportDataArray,
    handleShowProductReports,
    getWeeklyProductReportData,
    getWeeklyProductReportMDList,
  } = props;
  const date = new Date();

  // Get weekly product report reducer data
  const prodReportReducer = useSelector((state) => state.productReportReducer);
  const weeklyProdReportData =
    prodReportReducer && prodReportReducer?.weeklyProdReportData;

  const [monthList, setMonthList] = useState([]);
  const [weeklyChartData, setWeeklyChartData] = useState({
    options: {
      chart: {
        type: "bar",
        height: 250,
        toolbar: {
          show: false,
        },
      },
      colors: ["#96da7a"],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["#000"],
      },
      xaxis: {
        categories: ["Week1", "Week2", "Week3", "Week4", "Week5"],
      },
      grid: {
        show: false,
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: false,
      },
      series: [
        {
          name: "Series 1",
          data: [0, 0, 0, 0, 0],
        },
      ],
      noData: {
        text: "Data not found",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -30,
        style: {
          color: "#000",
          fontSize: "16px",
          fontFamily: "Cabin, sans-serif",
        },
      },
    },
  });

  // Get week list and Weeks by dates
  useEffect(() => {
    if (isVisibleReport === true) {
      const getMonthsList = getStartDateAndEndDate(
        "month",
        "",
        date.getMonth()
      );
      if (getMonthsList) setMonthList(getMonthsList.month);
    }
  }, [isVisibleReport]);

  // Update weekly report chart data
  useEffect(() => {
    let data = reportDataArray.map((item) => item.qty);
    let checkData = reportDataArray.every((item) => item.qty === 0);
    setWeeklyChartData({
      ...weeklyChartData,
      options: {
        ...weeklyChartData.options,
        series: [
          {
            name: "Card",
            data: checkData === false ? data : [],
          },
        ],
      },
    });
  }, [reportDataArray]);

  return (
    <div
      className={`tc-product2-section tc-sidebar-section ${
        isVisibleReport && "tc-product2-sidebar"
      }`}
    >
      <div className="tc-product-navbar">
        <button
          type="button"
          className="tc-product-menu"
          onClick={() => handleShowProductReports()}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" />
          </svg>
        </button>
        <div className="tc-product-text">
          <h2>{selectedProduct && selectedProduct?.name}</h2>
        </div>
      </div>
      <div className="tc-product-body">
        <div className="tc-body-content tc-dropdown-hed-body-content">
          <h1 className="tc-main-title">Sale Per Week</h1>
          <div className="tc-daily-number tc-weekly-number">
            <div className="tc-select-box">
              as of
              <Form.Select onChange={(e) => getWeeklyProductReportData(e)}>
                {monthList &&
                  monthList.map((month, index) => {
                    return (
                      <option value={month?.monthDetails?.value} key={index}>
                        {month?.monthDetails?.lable}
                      </option>
                    );
                  })}
              </Form.Select>
              {date.getFullYear()}
            </div>
            <p className="tc-number-text">
              <span className="tc-text-success">
                {" "}
                ₽{totalCardAmount && totalCardAmount}
              </span>
            </p>
          </div>
          <div className="tc-daily-chart">
            <ReactApexChart
              options={weeklyChartData.options}
              series={weeklyChartData.options.series}
              type="bar"
              height={250}
            />
          </div>
          <Table className="tc-table tc-daily-table tc-weekly-table">
            <thead>
              <tr>
                <th>Week</th>
                <th>#</th>
                <th>₽</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {weeklyProdReportData && weeklyProdReportData.length === 0 ? (
                <tr>
                  <td colSpan="4">
                    <div className="tc-datanot-found">
                      <img src={dataNotFound} alt="Data Not Found" />
                      <p>Data not found</p>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {reportDataArray &&
                    reportDataArray.length > 0 &&
                    reportDataArray.map((weekReport, index) => {
                      return (
                        <tr
                          key={index}
                          className={`${
                            weekReport.isVisible === false &&
                            "tc-table-row-disabled"
                          }`}
                        >
                          <td>Week {weekReport?.week_no}</td>
                          <td>{weekReport?.qty}</td>
                          <td>{weekReport?.card_total}</td>
                          <td>
                            <div
                              className="tc-sidebar-btn"
                              onClick={() =>
                                getWeeklyProductReportMDList(weekReport)
                              }
                            >
                              <FontAwesomeIcon icon={faCircleArrowRight} />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default WeeklyReport;
