import React, { useEffect, useState } from "react";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Loader from "../../component/Common/Loader";
import WeeklyMdDetails from "./WeeklyMdDetails";
import WeeklyCardDetails from "./WeeklyCardDetails";
import dataNotFound from "../../images/DataNotFound.svg";
import { getStartDateAndEndDate } from "../../helpers/commonFunction";

// API
import {
  reqToGetWeekWiseCardList,
  reqToGetWeekWiseMDList,
  reqToGetWeekWiseProductList,
  reqToGetWeeklyReportData,
} from "../../store/slice/weeklyReportSlice";

const WeeklyReport = () => {
  const dispatch = useDispatch();
  const date = new Date();
  const userReducer =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  // Get weekly reducer data
  const weeklyReportReducer = useSelector((state) => state.weeklyReportReducer);
  const weeklyReportData =
    weeklyReportReducer && weeklyReportReducer?.weeklyReportData;

  // Default weeks data of month
  const initialState = [
    { week_no: 1, card_total: 0, qty: 0, isVisible: false },
    { week_no: 2, card_total: 0, qty: 0, isVisible: false },
    { week_no: 3, card_total: 0, qty: 0, isVisible: false },
    { week_no: 4, card_total: 0, qty: 0, isVisible: false },
    { week_no: 5, card_total: 0, qty: 0, isVisible: false },
  ];

  const [monthList, setMonthList] = useState([]);
  const [totalCardAmount, setTotalCardAmount] = useState(0);
  const [reportDataArray, setReportDataArray] = useState(initialState);

  // Sidebar states
  const [showMDList, setShowMDList] = useState(false);
  const [showCardList, setShowCardList] = useState(false);

  // Selected data states
  const [selectMonth, setSelectMonth] = useState(date.getMonth());
  const [selectedWeekData, setSelectedWeekData] = useState();
  const [selectedMDData, setSelectedMDData] = useState();
  const [selectedProductData, setSelectedProductData] = useState();
  const [weeklyChartData, setWeeklyChartData] = useState({
    options: {
      chart: {
        type: "bar",
        height: 250,
        toolbar: {
          show: false,
        },
      },
      colors: ["#96da7a"],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["#000"],
      },
      xaxis: {
        categories: ["Week1", "Week2", "Week3", "Week4", "Week5"],
      },
      grid: {
        show: false,
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: false,
      },
      series: [
        {
          name: "Series 1",
          data: [0, 0, 0, 0, 0],
        },
      ],
      noData: {
        text: "Data not found",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -30,
        style: {
          color: "#000",
          fontSize: "16px",
          fontFamily: "Cabin, sans-serif",
        },
      },
    },
  });

  // Handle MD list sidebar
  const handleShowMDList = () => {
    setShowMDList(!showMDList);
  };

  // Handle card list sidebar
  const handleShowCardList = () => {
    setShowCardList(!showCardList);
  };

  // Get month list and month by weeks
  useEffect(() => {
    const getMonthsList = getStartDateAndEndDate("month", "", date.getMonth());
    if (getMonthsList) setMonthList(getMonthsList.month);

    // Get default current month data
    const getDates = getStartDateAndEndDate("monthDates", "", date.getMonth());
    dispatch(
      reqToGetWeeklyReportData({
        data: {
          start_date: moment(getDates.startDate).format("YYYY-MM-DD"),
          end_date: moment(getDates.endDate).format("YYYY-MM-DD"),
        },
        onSuccess: (response) => {
          if (response && response.length > 0) {
            updateWeeklyReportData(response);
          }
        },
      })
    );
  }, []);

  // Update weekly report chart data
  useEffect(() => {
    let data = reportDataArray.map((item) => item?.qty);
    let checkData = reportDataArray.every((item) => item?.qty === 0);
    setWeeklyChartData({
      ...weeklyChartData,
      options: {
        ...weeklyChartData.options,
        series: [
          {
            name: "Card",
            data: checkData === false ? data : [],
          },
        ],
      },
    });
  }, [reportDataArray]);

  // Get daily report by selected weeks
  const getWeeklyReportData = (event) => {
    setSelectMonth(event.target.value);
    const getDates = getStartDateAndEndDate(
      "monthDates",
      "",
      event.target.value
    );
    dispatch(
      reqToGetWeeklyReportData({
        data: {
          start_date: moment(getDates.startDate).format("YYYY-MM-DD"),
          end_date: moment(getDates.endDate).format("YYYY-MM-DD"),
        },
        onSuccess: (response) => {
          if (response && response.length > 0) {
            updateWeeklyReportData(response);
          } else {
            setReportDataArray(initialState);
          }
        },
      })
    );
  };

  // Update reportDataArray data and Count total card amount
  const updateWeeklyReportData = (data) => {
    let count = 0;
    for (let i = 0; i < data.length; i++) {
      setReportDataArray((prevState) => {
        const newState = prevState.map((prev) => {
          if (prev.week_no === data[i].week_no) {
            return { ...data[i], isVisible: true };
          }
          return prev;
        });
        return newState;
      });

      count = count + data[i]?.card_total;
      setTotalCardAmount(count);
    }
  };

  // Get MD data by selected week
  const getWeeklyReportMDList = (item) => {
    const getWeekDates = getStartDateAndEndDate(
      "day",
      item.week_no,
      selectMonth
    );
    setSelectedWeekData({
      ...item,
      startDate: getWeekDates.startDate,
      endDate: getWeekDates.endDate,
      weekDate:
        moment(getWeekDates.startDate).format("MMM") +
        " " +
        moment(getWeekDates.startDate).format("D") +
        " to " +
        moment(getWeekDates.endDate).format("D") +
        ", " +
        date.getFullYear(),
    });
    dispatch(
      reqToGetWeekWiseMDList({
        data: {
          start_date: getWeekDates.startDate,
          end_date: getWeekDates.endDate,
        },
        onSuccess: () => {
          handleShowMDList();
        },
      })
    );
  };

  // Get card details by selected MD
  const getWeeklyReportCardList = (item) => {
    setSelectedMDData(item);
    dispatch(
      reqToGetWeekWiseCardList({
        data: {
          start_date: selectedWeekData.startDate,
          end_date: selectedWeekData.endDate,
          doctor: item?.doctor_id,
        },
        onSuccess: () => {
          handleShowCardList();
          setSelectedProductData("");
        },
      })
    );
  };

  // Get product details by selected card
  const getWeeklyReportProductList = (item) => {
    if (selectedProductData?.card_id !== item?.card_id) {
      dispatch(
        reqToGetWeekWiseProductList({
          data: {
            start_date: selectedWeekData.startDate,
            end_date: selectedWeekData.endDate,
            card: item.card_id,
          },
          onSuccess: () => {
            setSelectedProductData(item);
          },
        })
      );
    } else {
      setSelectedProductData("");
    }
  };

  return (
    <>
      {weeklyReportReducer && weeklyReportReducer?.loader && <Loader />}
      <div className="tc-body-section">
        <div className="tc-body-content">
          <h1 className="tc-main-title">TCard per Weekly</h1>
          <div className="tc-daily-number tc-weekly-number">
            <div className="tc-select-box">
              as of
              <Form.Select onChange={(e) => getWeeklyReportData(e)}>
                {monthList &&
                  monthList.map((month, index) => {
                    return (
                      <option value={month?.monthDetails?.value} key={index}>
                        {month?.monthDetails?.lable}
                      </option>
                    );
                  })}
              </Form.Select>
              {date.getFullYear()}
            </div>
            <p className="tc-number-text">
              <span className="tc-text-success">
                {" "}
                ₽{totalCardAmount && totalCardAmount}
              </span>
            </p>
          </div>
          <div className="tc-daily-chart">
            <ReactApexChart
              options={weeklyChartData.options}
              series={weeklyChartData.options.series}
              type="bar"
              height={250}
            />
          </div>
          <Table className="tc-table tc-daily-table tc-weekly-table">
            <thead>
              <tr>
                <th>week</th>
                <th>#</th>
                <th>₽</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {weeklyReportData && weeklyReportData.length === 0 ? (
                <tr>
                  <td colSpan="4">
                    <div className="tc-datanot-found">
                      <img src={dataNotFound} alt="Data Not Found" />
                      <p>Data not found</p>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {reportDataArray &&
                    reportDataArray.map((weekReport, index) => {
                      return (
                        <tr
                          key={index}
                          className={`${
                            weekReport?.isVisible === false &&
                            "tc-table-row-disabled"
                          }`}
                        >
                          <td>Week {weekReport?.week_no}</td>
                          <td>{weekReport?.qty}</td>
                          <td>{weekReport?.card_total}</td>
                          <td>
                            <div
                              className="tc-sidebar-btn"
                              onClick={() => getWeeklyReportMDList(weekReport)}
                            >
                              <FontAwesomeIcon icon={faCircleArrowRight} />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </Table>

          {/* Weekly report MD list sidebar*/}
          <WeeklyMdDetails
            showMDList={showMDList}
            userReducer={userReducer}
            selectedWeekData={selectedWeekData}
            handleShowMDList={handleShowMDList}
            getWeeklyReportCardList={getWeeklyReportCardList}
          />

          {/* Weekly report card list sidebar*/}
          <WeeklyCardDetails
            showCardList={showCardList}
            userReducer={userReducer}
            handleShowCardList={handleShowCardList}
            selectedWeekData={selectedWeekData}
            selectedMDData={selectedMDData}
            selectedProductData={selectedProductData}
            getWeeklyReportProductList={getWeeklyReportProductList}
          />
        </div>
      </div>
    </>
  );
};

export default WeeklyReport;
