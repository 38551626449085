import React from "react";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dataNotFound from "../../../images/DataNotFound.svg";

const MonthlyMdDetails = (props) => {
  const {
    showMDList,
    selectedProduct,
    handleShowMDList,
    selectedReportData,
    getMonthlyProductReportCardList,
  } = props;

  // Get monthly product report reducer data
  const prodReportReducer = useSelector((state) => state.productReportReducer);
  const monthlyProdReportMDList = prodReportReducer?.monthlyProdReportMDList;

  return (
    <div
      className={`tc-sidebar-section tc-product-mdlist-sidebar ${
        showMDList && "zl-sidebar-open"
      }`}
    >
      <div className="tc-navbar-section tc-report-navbar">
        <div className="tc-navbar-content">
          <div className="tc-navbar-menu">
            <div
              className="tc-navbar-back"
              onClick={() => handleShowMDList("monthly")}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" />
              </svg>
            </div>
          </div>
          <div className="tc-product-text">
            <h2>{selectedProduct && selectedProduct?.name}</h2>
          </div>
        </div>
      </div>
      <div className="tc-daily-content tc-body-content">
        <h1 className="tc-main-title">
          {selectedReportData &&
            selectedReportData?.month_name.concat(
              " ",
              selectedReportData?.year
            )}
        </h1>
        <div className="tc-daily-number">
          <p className="tc-number-text">
            <span className="tc-text-success">
              ₽{selectedReportData && selectedReportData?.p_total}
            </span>
          </p>
        </div>
        <Table className="tc-table tc-daily-table tc-weekly-table">
          <thead>
            <tr>
              <th>md name</th>
              <th>#</th>
              <th>₽</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {monthlyProdReportMDList &&
            monthlyProdReportMDList?.length === 0 ? (
              <tr>
                <td colSpan="4">
                  <div className="tc-datanot-found">
                    <img src={dataNotFound} alt="Data Not Found" />
                    <p>Data not found</p>
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {monthlyProdReportMDList &&
                  monthlyProdReportMDList.map((mdData, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {mdData?.firstname.concat(" " + mdData?.lastname)}
                        </td>
                        <td>{mdData?.qty}</td>
                        <td>{mdData?.card_total}</td>
                        <td>
                          <div
                            className="tc-sidebar-btn"
                            onClick={() =>
                              getMonthlyProductReportCardList(mdData)
                            }
                          >
                            <FontAwesomeIcon icon={faCircleArrowRight} />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default MonthlyMdDetails;
